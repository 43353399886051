.c-row {
  display: flex;
  flex-direction: row;
}

.row--no-gutter > .col {
  padding-right: 0;
  padding-left: 0;
}

.row--no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.container {
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.c-logo {
  position: static;
  display: block;
  margin-right: 0;
  margin-left: 0;
  transform: none;
  width: 160px;
  height: 230px;
  border: 1px solid #eee;
}

.c-col--xs-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.c-logo-img {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 80%;
  transform: translate(-50%, -50%);
}

.c-production-img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: opacity ease 300ms;
  opacity: 0;
}

.c-production-img.is-active {
  opacity: 1 !important;
}

.c-thumb-slider-item {
  width: calc(25% - (8px + 6px));
  height: 70px;
  border: 3px solid transparent;
  cursor: pointer;
  transition: border-color ease 300ms;
  margin-right: 8px;
  margin-bottom: 8px;
  flex: 0 0 auto;
  box-sizing: content-box;
}

.c-thumb-slider-item.is-active {
  border-color: var(--blue);
}

.c-career-button {
  border-radius: 0;
  color: #fff;
  border-width: 1px;
  background-color: var(--purple);
  border-color: var(--purple);
  transition-property: background-color, color, border-color;
  position: relative;
  display: inline-block;
  padding: 0.625rem 1.25rem;
  border: 0 solid transparent;
  cursor: pointer;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  padding: 12px 24px;
  font-size: 16px;
}

.c-career-button:hover {
  background-color: var(--darkPurple);
  border-color: var(--darkPurple);
  color: #fff;
}

.u-flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.pdf-view{
  width: 100%;
  height: 800px;
}

@media (max-width: 1024px) {
  .c-thumb-slider-item {
    width: 15%;
    margin-top: 1.5rem;
  }
}

@media (min-width: 75rem) {
  .container {
    max-width: 71.25rem;
  }
}

@media (min-width: 62rem) and (max-width: 75rem) {
  .container {
    max-width: 58.75rem;
  }
}

@media (min-width: 48rem) and (max-width: 62rem) {
  .container {
    max-width: 45rem;
  }
}

@media (min-width: 34rem) and (max-width: 48rem) {
  .container {
    max-width: 36rem;
  }
}

@media (min-width: 62rem) {
  .c-col--lg-2 {
    max-width: 16.66667%;
    flex: 0 0 16.66667%;
  }

  .c-col--lg-5 {
    max-width: 41.6666666667%;
    -webkit-box-flex: 0;
    flex: 0 0 41.6666666667%;
  }

  .c-col--lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .c-col--lg-7 {
    max-width: 58.3333333333%;
    -webkit-box-flex: 0;
    flex: 0 0 58.3333333333%;
  }

  .c-col--lg-8 {
    max-width: 66.66666667%;
    flex: 0 0 66.6666666667%;
  }

  .c-col--lg-10 {
    max-width: 83.3333333333%;
    -webkit-box-flex: 0;
    flex: 0 0 83.3333333333%;
  }

  .c-col--lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .c-col--offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .c-col--offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .c-col--offset-lg-0 {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .pdf-view{
    width: 100%;
    height: 500px;
  }
}
