.c-new-page-title {
  font-size: 3em;
  font-weight: bold;
  line-height: 1.1666666667;
}

.c-new-page-date {
  color: var(--blue);
}

.c-new-page-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.c-new-page-col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
}

p > img {
  max-width: 100%;
  height: auto;
}

p > img + p > img {
  margin-top: 1rem;
}

@media (min-width: 62rem) {
  .c-new-page-col {
    margin-left: 16.6666667%;
    max-width: 66.6666666667%;
  }
}

@media (max-width: 47.9375rem) {
  .c-new-page-title {
    font-size: 2em;
  }
}
