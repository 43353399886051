.c-list {
  margin-top: 1rem;
  list-style-type: disc;
  display: block;
  padding-left: 0;
  margin-bottom: 0;
  list-style-image: initial;
}

.c-list-item {
  display: list-item;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.c-list-item::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}

.c-list-item + .c-list-item {
  margin-top: 8px;
}

@media (min-width: 62rem) {
  .c-social-img-wrapper {
    width: 100%;
    flex: 0 0 45%;
  }
}
