.c-aftermarket {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  gap: 1rem;
}

.afm-img {
  width: 100%;
  height: 100%;
}
