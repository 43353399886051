@import "bootstrap/dist/css/bootstrap.min.css";

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/GoogleSans-Regular.ttf") format("truetype");
}

strong {
  text-align: start;
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/GoogleSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/GoogleSans-Bold.ttf") format("truetype");
}

:root {
  --blue: #4bc0e9;
  --black: #212121;
  --purple: #79288c;
  --darkPurple: #682278;
  --darkBlue: #1baadc;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

body {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  font-family: "Google Sans" !important;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
