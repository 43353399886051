.c-career-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.c-career-list {
  margin-top: 1rem;
  list-style-type: disc;
  display: block;
  margin-bottom: 0;
  list-style-image: initial;
}

.c-career-container {
  margin-bottom: 6rem;
}

.c-career-form-group {
  margin-top: 1.5rem;
  position: relative;
  display: block;
}

.c-career-form-group-label {
  display: block;
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.c-career-form-control {
  display: block;
  width: 100%;
  height: 3.25rem;
  padding: 0.8125rem 1rem;
  background-image: none;
  border-style: solid;
  border-width: 1px;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: inherit;
  border-color: #ccc;
  color: #555;
  box-sizing: border-box;
}

.c-career-form-group-input {
  display: block;
  margin-top: 0.5rem;
}

@media (min-width: 62rem) {
  .c-career-pad-sides {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
