.contact-map {
  position: relative;
  width: 58.333333%;
  max-width: 58.3333333333%;
  height: 350px;
}

.col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
}

.contact-container {
  display: flex;
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.contact-touch {
  max-width: 41.6666666667%;
  position: relative;
  width: 100%;
  padding-right: 1.5rem;
}

.form {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  column-gap: 10%;
}

.form-group {
  position: relative;
  display: block;
  margin-top: 1.5rem;
}

.form-group-label {
  display: block;
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.form-group-input {
  display: block;
  margin-top: 0.5rem;
  height: 1.75rem;
  width: 100%;
  padding: 0.8125rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border-color: #ccc;
  color: #555;
  border-style: solid;
  border-width: 1px;
}

.message {
  max-width: 100% !important;
  flex: 0 0 100% !important;
  width: 100% !important;
}

.message-input {
  height: auto;
  overflow: auto;
  resize: none;
}

@media screen and (min-width: 62rem) {
  .form-container {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .form-input-container {
    max-width: 45%;
    -webkit-box-flex: 0;
    flex: 0 0 45%;
  }
}

@media screen and (max-width: 960px) {
  .contact-touch {
    max-width: 90%;
  }

  .contact-map {
    max-width: 100%;
    width: 90%;
    margin-top: 3rem;
  }

  .contact-container {
    flex-direction: column;
  }

  .form {
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0;
    column-gap: 0;
  }
}
