.c-hero {
    position: relative;
    width: 100%;
    padding-bottom: 27.7777777778%;
  }
  
  .c-hero-background {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .c-hero-body {
    padding-right: 2rem;
    padding-left: 2rem;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    max-width: 45rem;
    color: #fff;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  
  .c-hero-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translate(-50%, -50%);
  }
  
  .c-hero-image-focus-top {
    top: 0;
    bottom: auto;
    transform: translate(-50%, 0);
  }
  
  .c-about-caption {
    display: block;
  }
  
  .c-map-image {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .c-map-content {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  
  .c-about-counter-wrapper {
    display: block;
    width: 100%;
    text-align: center;
  }
  
  .c-about-map-counter {
    text-align: center;
    font-size: 100px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1;
  }
  
  .c-about-map-counter-label {
    color: var(--blue);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1;
    text-transform: uppercase;
  }
  
  .c-about-map-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
  }
  
  .c-about-map-content {
    position: absolute;
    top: 50%;
    display: flex;
    width: 100%;
    justify-content: space-around;
    transform: translateY(-50%);
  }
  
  @media (min-width: 62rem) {
    .c-col-about {
      margin-left: 16.66666667%;
      max-width: 66.666666667%;
    }
  }
  
  @media (max-width: 62rem) {
    .c-about-map-counter {
      font-size: 50px;
    }
    .c-about-counter-wrapper {
      margin-bottom: 1.5rem;
    }
  }
  
  @media (max-width: 900px) {
    .c-hero {
      height: 250px;
      padding-bottom: 0;
    }
  }
  
  @media (min-width: 1440px) {
    .c-hero-image {
      width: 100%;
      height: auto;
    }
  
    .c-hero {
      height: 400px;
      padding-bottom: 0;
    }
  }
  