.c-footer-wrapper {
  margin-top: 150px;
}

.c-footer {
  padding: 5.625rem 0;
  background: right top no-repeat;
  background-image: url("../../assets/img/footer-bg.svg"),
    linear-gradient(to bottom, #484848, #414141);
}

.c-footer-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.c-footer-col {
  position: relative;
  width: 100%;
  min-height: 1px;
}

.c-footer-primary {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: var(--blue);
}

.c-footer-list {
  display: block;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.c-footer-list-item {
  position: relative;
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.c-footer-list-link {
  color: #fff;
}

.c-footer-list-item + .c-footer-list-item {
  margin-top: 8px;
}

.linkedin-btn {
  color: white;
  margin-top: 1rem;
  transition: color ease 300ms;
}

.linkedin-btn:hover {
  color: var(--blue);
}

@media (min-width: 62rem) {
  .c-footer-col {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

@media (max-width: 62rem) {
  .c-footer-col {
    margin-bottom: 1.5rem;
  }
}
