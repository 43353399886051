.button-container {
  display: flex;
  flex-direction: column;
  max-width: 25%;
  flex: 0 0 25%;
}

@media (max-width: 960px) {
  .button-container {
    flex-direction: row;
    max-width: 100%;
    flex: 0 0 100%;
    column-gap: 12px;
  }
}
