a {
  text-decoration: none;
}

a:hover {
  color: var(--blue);
}

li {
  text-align: -webkit-match-parent;
}

.c-header {
  position: sticky;
  top: 0;
  box-shadow: 1px 1px 6px 0px;
  z-index: 9999;
  padding: 2rem;
  background-color: #fff;
}

.c-header.is-scrolling {
  box-shadow: 0 2px 42px rgba(0, 0, 0, 0.1);
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.header-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0;
}

.logo-container {
  flex: 1 0 auto;
}

.logo-container > img {
  vertical-align: middle;
  border-style: none;
}

.list-container {
  position: relative;
  display: block;
}

.list {
  width: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
}

.list-item {
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  font-weight: 700;
}

.list-item-link {
  display: block;
  padding: 8px 10px;  /* 8px 16px */
  color: var(--black);
  transition: color ease 300ms;
  cursor: pointer;
}

.list-item-link:hover {
  text-decoration: none;
  color: var(--blue);
}

.list-dropdown {
  left: 0;
  display: none;
  padding-left: 0;
  margin-bottom: 0;
  background-color: white;
  box-shadow: 0 8px 24px rgb(0 0 0 / 10%);
  list-style: none;
  transition: all ease 300ms;
  visibility: hidden;
  opacity: 0;
}

.list-dropdown-item {
  display: flex;
}

.list-dropdown-link {
  color: var(--black);
  padding: 16px 24px;
  transform: ease 300ms;
  font-weight: normal;
  width: 100%;
  background-color: white;
  transition-property: background-color, color;
}

.list-dropdown-link:hover {
  background-color: #ebeef0;
}

.header-hamburger.is-active + .list-container {
  transform: translateX(0);
}

.header-hamburger {
  display: none;
}

.dropdownOpen {
  display: block;
  opacity: 1;
  visibility: visible;
}


@media (min-width: 1280px) {
  .list-item:hover > .list-dropdown {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  .list-dropdown-navigation {
    position: absolute;
    top: 100%;
    width: 280px;
    height: auto !important;
    overflow: hidden;
    overflow-y: auto;
    max-height: 600px;
  }
}

/*
    STYLES FOR MOBILE
*/
@media (max-width: 1280px) {
  .header-hamburger {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .container {
    max-width: 728px;
  }
  .header-container {
    max-width: 100%;
  }

  .list-container {
    position: fixed;
    top: 0;
    right: 0;
    overflow: auto;
    width: 100vw;
    height: calc(100vh - 100px);
    align-items: flex-start;
    margin-top: 100px;
    background-color: white;
    transform: translateX(100%);
    transition: transform ease 300ms, -webkit-transform ease 300ms;
  }
  .list {
    display: flex;
    flex-direction: column;
  }

  .list-dropdown {
    box-shadow: none;
  }

  .list-dropdown-navigation {
    overflow: hidden;
  }
}


@media (max-width: 768px) {
  .c-header {
    position: sticky;
    top: 0;
    box-shadow: 1px 1px 6px 0px;
    z-index: 9999;
    padding: 1rem;
    background-color: #fff;
  }
  .header-hamburger {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .container {
    max-width: 728px;
  }
  .header-container {
    max-width: 100%;
  }

  .list-container {
    position: fixed;
    top: 0;
    right: 0;
    overflow: auto;
    width: 100vw;
    height: calc(100vh - 70px);
    align-items: flex-start;
    margin-top: 70px;
    background-color: white;
    transform: translateX(100%);
    transition: transform ease 300ms, -webkit-transform ease 300ms;
  }
  .list {
    display: flex;
    flex-direction: column;
  }

  .list-dropdown {
    box-shadow: none;
  }

  .list-dropdown-navigation {
    overflow: hidden;
  }
}