.c-products-title {
  font-size: 28px;
  font-weight: 700;
}

.c-products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.c-products-list-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30%;
  width: 100%;
  border: 1px solid #eee;
}

.c-products-list-item-img {
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease-in-out;
}

.c-products-list-item-img:hover {
  transform: scale(1.05);
}

.c-products-list-item-title {
  padding-top: 1rem;
  font-size: 18px;
  color: var(--blue);
  text-align: center;
  transition: color 0.2s ease-in-out;
}

.c-products-list-item-title:hover {
  color: var(--darkBlue);
}

@media (max-width: 768px) {
  .c-products-list {
    flex-direction: column;
  }

  .c-products-list-item {
    max-width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .c-products-list-item {
    max-width: 40%;
  }
}
