.news-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 350px));
  gap: 12px;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.news-card {
  position: relative;
  flex: 3 3 350px;
  display: block;
}

.c-news-body {
  position: relative;
  overflow: hidden;
}

.c-news-title {
  margin-top: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-news-img {
  object-fit: cover;
  object-position: center;
}

.content {
  line-height: 1.6em;
  max-height: 3.2em;
}

.news-page-button {
  width: 100%;
  padding-top: 3rem;
  display: flex;
  margin: 0;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.c-pagination {
  display: flex;
}

.c-pagination-item {
  display: list-item;
  cursor: pointer;
}

.c-pagination-item + .c-pagination-item {
  margin-left: 0.5rem;
}

.c-pagination-item.is-active > .c-pagination-link {
  background-color: var(--blue);
  color: #eee;
  transition: all 300ms ease;
  cursor: no-drop;
  pointer-events: none;
}
.c-pagination-item:hover > .c-pagination-link {
  background-color: var(--blue);
  color: #eee;
  transition: all 300ms ease;
}

.c-pagination-link {
  display: block;
  padding: 1em;
  border: 1px solid #eee;
  line-height: 1;
  font-size: 1em;
}

.c-pagination-link.disabled {
  cursor: no-drop;
  opacity: 0.5;
  pointer-events: none;
}

.c-pagination-link.disabled:hover {

}

@media (max-width: 1280px) {
  .news-card {
    max-width: 100%;
    margin-top: 3rem;
  }
}
