.c-protection-list {
  list-style: decimal;
}

.c-protection-list-insidelist {
  list-style-type: disc;
}

.c-container > p {
  display: flex;
  flex-direction: column;
}

.c-container > p > strong {
  text-align: center;
}
