div {
  display: block;
}

a {
  color: var(--blue);
  text-decoration: none;
  transition: color ease 300ms;
}

a:hover {
  color: #199fce;
  text-decoration: none;
}

.c-slider-title {
  font-weight: 700;
  text-shadow: 1px 1px #000;
  margin-bottom: 0;
  font-size: 3em;
}

.c-slider-description {
  text-shadow: 1px 1px #000;
}

.c-col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
}

.c-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.c-slider {
  width: 100vw;
  position: relative;
}

.carousel-item {
  position: relative;
  height: 650px;
  box-sizing: content-box;
  overflow: hidden;
}

.c-slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 45rem;
  color: #fff;
  text-align: center;
}

.carousel-buttons {
  display: flex;
  gap: 8px;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: center;
}

.c-slider-image-container > div {
  color: #fff;
}

.c-container {
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.c-together-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  padding: 2rem 0;
}

.c-container-text-wrapper {
  max-width: 30%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
}

.c-container-img-container {
  max-width: 60%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: 15% 15% 15% 15%;
  column-gap: 2rem;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
}

.c-logo-wrapper {
  display: flex;
  justify-content: center;
}

.c-container-text {
  font-size: xx-large;
  font-weight: bold;
  text-align: end;
}

.c-container-logo {
  max-width: 100%;
  height: auto;
}

hr {
  width: 100%;
  border: 0;
  border-top: 1px solid;
  color: #eee;
}

.c-corporate {
  padding: 3rem 0;
}

.c-container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-caption-header {
  line-height: 1.125;
  font-weight: bold;
  margin: 0;
  font-size: 4em;
}

.c-caption-section {
  margin-bottom: 0.5rem;
  color: #4bc0e9;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.c-card-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  column-gap: 20px;
}

.c-ratio {
  position: relative;
  overflow: hidden;
}

.c-ratio::before {
  display: block;
  height: 0;
  content: "";
}

.c-ratio--4-3 {
  padding-bottom: calc((3 / 4) * 100%);
}

.c-card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-card-title {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 700;
}

.card-container {
  text-align: center;
  position: relative;
}

.c-caption-title {
  margin: 0;
  font-size: 2em;
  font-weight: bold;
  line-height: 1.25;
  text-transform: none;
}

.c-caption-large {
  font-size: 3em;
  line-height: 1.16666667;
}

.c-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-learn-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.c-button {
  background-color: #4bc0e9;
  border-color: #4bc0e9;
  border-radius: 0;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  position: relative;
  display: inline-block;
  border: 0 solid transparent;
  cursor: pointer;
  transition-property: background-color, color, border-color;
  transition-duration: 0.4s;
  padding: 12px 24px;
  font-size: 16px;
}

.c-button:hover {
  background-color: var(--darkBlue);
  border-color: #1baadc;
  color: #fff;
}

.c-locations-container {
  padding: 4rem 0;
  background-color: var(--black);
}

.c-caption-white {
  color: #fff;
}

.c-caption-content {
  margin-top: 8px;
}

.c-caption {
  display: block;
  margin-bottom: 1.5rem;
}

.c-map-wrapper {
  position: relative;
  width: 100%;
  min-height: 1px;
}

.c-map {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.c-map-locations-content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.c-map-list {
  border-left: 5px solid #4bc0e9;
  line-height: 32px;
  display: block;
  margin-bottom: 0;
}

.c-map-list-item {
  position: relative;
  display: block;
  letter-spacing: 0.5px;
  font-size: 16px;
  color: #fff;
}

.c-products {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.c-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -1rem;
  margin-right: -1rem;
}

.c-products-col {
  position: relative;
  width: 100%;
  min-height: 1px;
}

.c-caption-container {
  padding-right: 3rem;
  padding-left: 3rem;
}

.c-products-caption {
  margin-bottom: 1.5rem;
  display: block;
}

.c-products-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.c-corporate {
  background-color: #ebeef0;
}

.c-corporate-wrapper {
  display: flex;
  margin-right: -1rem;
  margin-left: -1rem;
}

.c-corporate-content-container {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
}

.c-corporate-content-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.c-corporate-timeline {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
}

.c-timeline-opacity-effect {
  background-image: linear-gradient(
    to bottom,
    #ebeef0 5%,
    rgba(235, 238, 240, 0)
  );
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 200px;
  content: "";
  pointer-events: none;
}
.c-timeline-opacity-effect.top {
  top: 0;
}
.c-timeline-opacity-effect.bot {
  bottom: 0;
  background-image: linear-gradient(to top, #ebeef0 5%, rgba(235, 238, 240, 0));
}

.c-corporate-timeline-scroll {
  overflow: auto;
  width: calc(100% + 40px);
  min-height: 300px;
  max-height: 70vh;
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  overflow-x: hidden;
}

.c-timeline {
  width: 100%;
  position: relative;
}

.c-timeline-lines {
  position: absolute;
  top: 0;
  left: 50%;
  width: 5px;
  height: 100%;
  background: linear-gradient(#ced5da 50%, rgba(206, 213, 218, 0) 0%) right
    repeat-y;
  background-size: 5px 30px;
  content: "";
  transform: translateX(-50%);
}

.c-timeline-item {
  position: relative;
  width: calc(35%);
  max-width: 260px;
  padding: 16px;
  margin: 0 auto;
  background-color: #fff;
}

.c-timeline-item + .c-timeline-item {
  margin-top: -125px;
}

.c-timeline-item:nth-child(odd) {
  transform: translateX(-50%) translateX(-64px);
}

.c-timeline-item:nth-child(even) {
  transform: translateX(50%) translateX(32px);
}

.c-timeline-image {
  height: 190px;
  margin-bottom: 16px;
}

.c-timeline-content {
  text-align: right;
}

.c-timeline-item:nth-child(odd) > .c-timeline-content-line {
  right: -64px;
}

.c-timeline-item:nth-child(even) > .c-timeline-content-line {
  left: -32px;
}

.c-timeline-content-line {
  position: absolute;
  z-index: 1;
  top: 233px;
  height: 2px;
  background-color: #4bc0e9;
  width: 48px;
}

.c-timeline-date {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--blue);
}

.c-news-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.c-news {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.c-news-card {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
}

.c-ratio--16-9 {
  padding-bottom: calc((9 / 16) * 100%);
}

.c-ratio-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-news-title {
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 1.33333;
}

.c-news-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-news-body {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 8px;
  color: #212121;
}

.c-news-date {
  margin-bottom: 4px;
  color: var(--blue);
  font-size: 0.875rem;
  line-height: 1.5;
}

.c-news-content {
  line-height: 1.5rem;
  color: black;
  max-height: 3rem;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
  overflow: hidden;
}

@media (max-width: 1280px) {
  .c-container {
    display: flex;
    flex-direction: column;
  }

  .carousel-buttons {
    flex-direction: column;
  }

  .card-container {
    margin-top: 24px;
  }

  .c-map-content {
    position: static;
    margin-top: 3.125rem;
  }

  .c-map-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transform: none;
    position: static;
    line-height: 1;
    border: 0;
  }

  .c-map-list-item {
    padding: 8px;
    border-bottom: 2px solid #4bc0e9;
    margin: 0 4px;
  }

  .c-caption-wrapper {
    max-width: 100%;
    text-align: center;
  }
}

@media (min-width: 75rem) {
  .c-container {
    max-width: 71.25rem;
  }
}

@media (min-width: 62rem) {
  .c-footer-col {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .c-slider-title {
    font-size: 4rem;
  }

  .c-logo-wrapper {
    margin-left: 1.5rem;
  }

  .c-products-col {
    max-width: 50%;
    width: 100%;
  }
}

@media (min-width: 48rem) {
  .c-news-card {
    max-width: 30.333333333%;
  }
}

@media (max-width: 62rem) {
  .c-footer-col {
    margin-bottom: 1.5rem;
  }

  .c-container-text-wrapper {
    max-width: 100%;
    text-align: center;
  }

  .c-container-img-container {
    max-width: 100%;
    margin-top: 1.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .c-logo-wrapper {
    max-width: 50%;
    width: 100%;
  }

  .c-container-text {
    text-align: center;
  }
}

@media (min-width: 62rem) and (max-width: 75rem) {
  .c-container {
    max-width: 58.75rem;
  }
}
@media (min-width: 48rem) and (max-width: 62rem) {
  .c-container {
    max-width: 45rem;
  }

  .c-caption-header {
    font-size: 3em;
  }
}
@media (min-width: 34rem) and (max-width: 48rem) {
  .c-container {
    max-width: 36rem;
  }
}

@media (max-width: 48rem) {
  .c-caption-header {
    font-size: 2em;
  }

  .c-caption {
    text-align: center;
  }

  .c-caption-large {
    font-size: 2em;
  }

  .c-caption-text-center {
    text-align: center;
  }

  .c-timeline-item {
    width: 85%;
    max-width: 85%;
    margin: inherit;
  }

  .c-timeline-item:not(:last-child) {
    margin-bottom: 32px;
  }

  .c-timeline-item:nth-child(odd),
  .c-timeline-item:nth-child(even) {
    transform: none;
  }

  .c-timeline-item + .c-timeline-item {
    margin-top: 0;
  }

  .c-timeline-content-line {
    display: none;
  }

  .c-timeline-lines {
    display: none;
  }
}

@media (max-width: 1024px) {
  .c-button {
    margin-bottom: 1.5rem;
  }
  .c-row {
    flex-direction: column;
  }

  .c-corporate-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .c-corporate-content-wrapper {
    text-align: center;
  }

  .c-timeline-content {
    text-align: inherit;
  }

  .c-container-logo {
    max-width: 50% !important;
  }

  .c-container-img-container {
    grid-template-columns: 25% 25% 25% 25%;
  }

  .c-caption-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1025px) {
  .c-corporate-content-container {
    max-width: 41.6666666667%;
    -webkit-box-flex: 0;
    flex: 0 0 41.6666666667%;
  }

  .c-corporate-timeline {
    max-width: 58.3333333333%;
    -webkit-box-flex: 0;
    flex: 0 0 58.3333333333%;
  }

  .c-caption-section,
  .c-caption-title,
  .c-caption-content {
    text-align: start;
  }
}


@media (max-width: 768px) {
  .carousel-item {
    position: relative;
    height: 550px;
    box-sizing: content-box;
    overflow: hidden;
  }

  .c-slider-title {
    font-weight: 700;
    text-shadow: 1px 1px #000;
    margin-bottom: 0;
    font-size: 2em;
  }
}
